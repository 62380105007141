/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { Input } from '@chakra-ui/react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'
/**
 * dataTypeID 54
 * dataTypeName  MAC Address
 *
 */
const Reminder = ({ field, isRequired, onValidate, isDisabled = true }) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const onChange = value => {
    if (!!isRequired) {
      if (!!value) {
        onValidate(true)
      } else {
        onValidate(false)
      }
    } else {
      onValidate(true)
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, value),
    })
  }
  let value = fields[field.field_id] ? fields[field.field_id].field_value : ''
  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange(fields[field.field_id].field_value)
    }
  }, [value])
  return (
    <Input
      autoComplete="off"
      type="text"
      isDisabled={isDisabled}
      placeholder={field.field_place_holder||`Enter ${field.field_name}`}
      borderRadius="md"
      size="sm"
      value={value}
      onChange={e => onChange(e.target.value)}
    />
  )
}

export default Reminder
