import React, { forwardRef } from 'react'
import { Input } from '@chakra-ui/react'
import {
  GA_CATEGORY_CUSTOM_INPUT,
  sectionDetailedTrack,
} from '../../../../containers/repository/repo.utils'

export const CustomDateInput = forwardRef(
  ({ field, value, onClick, isDisabled, onFocus }, ref) => {
    return (
      <Input
        autoComplete="off"
        type="text"
        isDisabled={isDisabled}
        borderRadius="md"
        size="md"
        w="full"
        value={value}
        readOnly={true}
        inputRef={ref}
        onClick={e => {
          sectionDetailedTrack({
            category: GA_CATEGORY_CUSTOM_INPUT,
            action: 'Date Input',
          })
          onClick(e)
        }}
        name={field.field_id}
        placeholder={field.field_place_holder || `Enter ${field.field_name}`}
        data-item={'hello'}
        onFocus={onFocus}
      />
    )
  }
)
