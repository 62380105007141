/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue, arithmaticCalcu } from '../../../../../utils/form.util'
import DatePicker from '../../../../date-picker'
import { CustomDateInput } from '../customDate'
import { format } from 'date-fns'

/**
 * dataTypeId 4
 * dataTypeName Date
 */

const DateTimeField = ({
	field,
	isRequired,
	onValidate,
	isDisabled = true,
	fieldIndex,
	fieldList,
	scrollToBottom = () => {},
}) => {
	const {
		state: { fields: formFields, toReFreshNumVal },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const onChange = value => {
		let formattedDate = format(new Date(value), 'yyyy-MM-dd HH:mm:ss')
		if (!!isRequired) {
			if (!!value) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, formattedDate),
		})
		dispatch({
			type: smartFormConst.TO_REFRESH_NUM_VAL,
		})
	}

	let value = !!fields[field.field_id] ? fields[field.field_id].field_value : ''

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onChange(fields[field.field_id].field_value)
		}
	}, [value])

	useEffect(() => {
		if (
			!!field?.field_inline_data?.formula?.length &&
			!!formFields[field.form_id]
		) {
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(
					field,
					arithmaticCalcu(
						field,
						formFields[field.form_id],
						fieldList
					).toString()
				),
			})
			onValidate(true)
		}
	}, [toReFreshNumVal])

	let minDate = field?.field_inline_data?.rag_submit_cutoff_datetime
	let maxDate = ''

	let excludeRange =
		field?.field_inline_data?.field_date_range_enabled &&
		field?.field_inline_data?.range_setting?.exclude_range
			? field.field_inline_data.range_setting.exclude_range
			: 0

	if (
		field?.field_inline_data?.field_date_range_enabled &&
		field?.field_inline_data?.range_setting?.flag_future_range_enabled
	) {
		let now = new Date()
		let newDate = ''
		const value = field?.field_inline_data?.range_setting
			?.field_future_date_range_value
			? field.field_inline_data.range_setting.field_future_date_range_value
			: 0

		if (field?.field_inline_data?.range_setting?.selected_range === 'Years') {
			let months = 12 * value

			for (var a = 1; a < months + 1; a++) {
				newDate = new Date(now.getFullYear(), now.getMonth() + a, now.getDate())
			}
			if (newDate) {
				maxDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		} else if (
			field?.field_inline_data?.range_setting?.selected_range === 'Months'
		) {
			for (var b = 1; b < value + 1; b++) {
				newDate = new Date(now.getFullYear(), now.getMonth() + b, now.getDate())
			}
			if (newDate) {
				maxDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		} else {
			for (var c = 1; c < value + 1; c++) {
				newDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + c)
			}
			if (newDate) {
				maxDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		}
	}
	return (
		<DatePicker
			className={`date-time-field-${toSnakeCase(field.field_name)}  ${
				fields[field.field_id]
					? new Date(fields[field.field_id].field_value)
					: new Date()
			}`}
			dateFormat='dd/MM/yyyy h:mm aa'
			customInput={<CustomDateInput field={field} isDisabled={isDisabled} />}
			id={`${field.field_name.split(' ').join('-')}`}
			showTimeSelect
			disabled={isDisabled}
			showPopperArrow={true}
			selectedDate={!!value ? (value === 'null' ? '' : new Date(value)) : ''}
			onChange={onChange}
			onFocus={e => scrollToBottom('select-scroll')}
			minDate={
				!!excludeRange
					? maxDate
						? maxDate
						: ''
					: minDate
					? new Date(minDate)
					: ''
			}
			maxDate={!!excludeRange ? '' : maxDate ? maxDate : ''}
			// popperPlacement={fieldIndex > 2 ? 'top-start' : 'bottom-start'}
		/>
	)
}

export default DateTimeField
