/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import Select from '../../../../chakra-react-select'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import {
  toSnakeCase,
  getMessageUniqueId,
} from '../../../../../utils/common.util'
/**
 *
 *  dataTypeID : 34
 *  dataTypeName : Multi Selection
 */

const MultiSelectionList = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  fieldIndex,
  scrollToBottom = () => {},
}) => {
  const {
    options,
    field_id,
    form_id,
    field_reference_id,
    field_name,
    data_type_id,
    data_type_category_id,
  } = field

  const {
    dispatch,
    state: { fields: formFields },
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const onChange = value => {
    if (Array.isArray(value)) {
      value = value.filter(Boolean)
      if (!!isRequired) {
        if (!!value) {
          onValidate(true)
        } else {
          onValidate(false)
        }
      } else {
        onValidate(true)
      }
      dispatch({
        type: smartFormConst.SET_FIELD_VALUE,
        fieldId: field.field_id,
        formId: field.form_id,
        value: {
          final: {
            form_id,
            field_id,
            field_reference_id,
            field_name,
            field_data_type_id: data_type_id,
            field_data_type_category_id: data_type_category_id,
            data_type_combo_id: 0,
            data_type_combo_value: 0,
            field_value: value.map(item => item.value).join('|'),
            message_unique_id: getMessageUniqueId(),
          },
          field_value: value,
        },
      })
    }
  }

  let value = !!fields[field.field_id] ? fields[field.field_id].field_value : ''

  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange(fields[field.field_id].field_value)
    }
  }, [JSON.stringify(value)])

  return (
    <Select
      className={`multi-selection-list-${toSnakeCase(field.field_name)}  ${
        fields[field.field_id] ? fields[field.field_id].field_value : ''
      }`}
      isMulti={true}
      isDisabled={isDisabled}
      id={field.field_name.split(' ').join('-')}
      options={Object.values(options)}
      onChange={onChange}
      placeholder={ field.field_place_holder||`Select ${field.field_name}`}
      borderRadius="md"
      //menuPlacement={fieldIndex > 2 ? 'top' : 'bottom'}
      value={value}
      size="sm"
      onInputChange={e => scrollToBottom('select-scroll')}
      onFocus={e => scrollToBottom('select-scroll')}
    />
  )
}

export default MultiSelectionList
