/* eslint-disable react-hooks/exhaustive-deps */
import moment from 'moment'
import React, { useEffect } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue, arithmaticCalcu } from '../../../../../utils/form.util'
import DatePicker from '../../../../date-picker'
import { CustomDateInput } from '../customDate'

/**
 * dataTypeId 1
 * dataTypeName Date
 */

const DateField = ({
	field,
	isRequired,
	onValidate,
	isDisabled = true,
	fieldIndex,
	fieldList,
	scrollToBottom = () => {},
}) => {
	const {
		state: { fields: formFields, toReFreshNumVal },
		dispatch,
	} = useSmartFormContext()
	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

	const onChange = value => {
		let formattedDate = ['null', 'undefined'].includes(value)
			? ''
			: moment(value).format('YYYY-MM-DD')
		if (!!isRequired) {
			if (!!value) {
				onValidate(true)
			} else {
				onValidate(false)
			}
		} else {
			onValidate(true)
		}
		dispatch({
			type: smartFormConst.SET_FIELD_VALUE,
			fieldId: field.field_id,
			formId: field.form_id,
			value: loadFinalValue(field, formattedDate),
		})
		dispatch({
			type: smartFormConst.TO_REFRESH_NUM_VAL,
		})
	}

	let value = !!fields[field.field_id] ? fields[field.field_id].field_value : ''

	useEffect(() => {
		if (!!fields[field.field_id] && fields[field.field_id].field_value) {
			onChange(fields[field.field_id].field_value)
		}
	}, [value])

	useEffect(() => {
		if (
			!!field?.field_inline_data?.formula?.length &&
			!!formFields[field.form_id]
		) {
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(
					field,
					arithmaticCalcu(
						field,
						formFields[field.form_id],
						fieldList
					).toString()
				),
			})
			onValidate(true)
		}
	}, [toReFreshNumVal])

	let minDate = ''
	let maxDate = ''
	let excludeRange =
		field?.field_inline_data?.field_date_range_enabled &&
		field?.field_inline_data?.range_setting?.exclude_range
			? field.field_inline_data.range_setting.exclude_range
			: 0
	if (
		field?.field_inline_data?.field_date_range_enabled &&
		field?.field_inline_data?.range_setting?.flag_past_range_enabled
	) {
		let now = new Date()
		let newDate = ''
		const value = field?.field_inline_data?.range_setting
			?.field_past_date_range_value
			? field.field_inline_data.range_setting.field_past_date_range_value
			: 0

		if (field?.field_inline_data?.range_setting?.selected_range === 'Years') {
			let months = 12 * value

			for (var i = 1; i < months + 1; i++) {
				newDate = new Date(now.getFullYear(), now.getMonth() - i, now.getDate())
			}
			if (newDate) {
				minDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		} else if (
			field?.field_inline_data?.range_setting?.selected_range === 'Months'
		) {
			for (var j = 1; j < value + 1; j++) {
				newDate = new Date(now.getFullYear(), now.getMonth() - j, now.getDate())
			}
			if (newDate) {
				minDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		} else {
			for (var k = 1; k < value + 1; k++) {
				newDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - k)
			}
			if (newDate) {
				minDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		}
	}

	if (
		field?.field_inline_data?.field_date_range_enabled &&
		field?.field_inline_data?.range_setting?.flag_future_range_enabled
	) {
		let now = new Date()
		let newDate = ''
		const value = field?.field_inline_data?.range_setting
			?.field_future_date_range_value
			? field.field_inline_data.range_setting.field_future_date_range_value
			: 0

		if (field?.field_inline_data?.range_setting?.selected_range === 'Years') {
			let months = 12 * value

			for (var a = 1; a < months + 1; a++) {
				newDate = new Date(now.getFullYear(), now.getMonth() + a, now.getDate())
			}
			if (newDate) {
				maxDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		} else if (
			field?.field_inline_data?.range_setting?.selected_range === 'Months'
		) {
			for (var b = 1; b < value + 1; b++) {
				newDate = new Date(now.getFullYear(), now.getMonth() + b, now.getDate())
			}
			if (newDate) {
				maxDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		} else {
			for (var c = 1; c < value + 1; c++) {
				newDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() + c)
			}
			if (newDate) {
				maxDate = new Date(
					newDate.getFullYear(),
					newDate.getMonth(),
					newDate.getDate()
				)
			}
		}
	}

	return (
		<DatePicker
			dateFormat='dd/MM/yyyy'
			className={`datepicker-field-${toSnakeCase(field.field_name)}`}
			customInput={
				<CustomDateInput
					onChange={onChange}
					field={field}
					isDisabled={isDisabled}
				/>
			}
			id={`${field.field_name.split(' ').join('-')}`}
			selectedDate={
				!!value
					? ['null', 'undefined'].includes(value)
						? ''
						: new Date(value)
					: ''
			}
			disabled={isDisabled}
			showPopperArrow={true}
			onChange={onChange}
			minDate={
				!!excludeRange ? (maxDate ? maxDate : '') : minDate ? minDate : ''
			}
			maxDate={
				!!excludeRange ? (minDate ? minDate : '') : maxDate ? maxDate : ''
			}
			onFocus={e => scrollToBottom('select-scroll')}
			// popperPlacement={fieldIndex > 2 ? 'top-start' : 'bottom-start'}
		/>
	)
}

export default DateField
