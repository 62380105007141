/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { Text } from '@chakra-ui/react'
import DatePicker from '../../../../date-picker'
import { CustomDateInput } from '../customDate'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'
import Select from '../../../../chakra-react-select'
import moment from 'moment'
import { format, addMinutes } from 'date-fns'
import { useReferredActivity } from '../../../../../hooks/activity.hooks'
/**
 * dataTypeId 3
 * dataTypeName Date
 */

const EventSchedule = ({
	field,
	isRequired,
	onValidate,
	isDisabled = true,
	fieldList,
}) => {
	const {
		state: { fields: formFields },
		dispatch,
	} = useSmartFormContext()

	const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
	const { field_id } = field
	const options = [
		{ label: '15 Min', value: '15' },
		{ label: '30 Min', value: '30' },
		{ label: '45 Min', value: '45' },
		{ label: '1 Hr', value: '60' },
		{ label: '1.5 Hr', value: '90' },
		{ label: '2 Hrs', value: '120' },
		{ label: '2.5 Hrs', value: '150' },
		{ label: '3 Hrs', value: '180' },
		{ label: '3.5 Hrs', value: '210' },
		{ label: '4 Hrs', value: '240' },
		{ label: '4.5 Hrs', value: '270' },
		{ label: '5 Hrs', value: '300' },
	]

	let formatValue = {
		start_date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
		duration: '0',
		end_date_time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
	}
	const { mutate: blockedDatesData } = useReferredActivity()
	const [startDateTime, setStartDateTime] = useState(new Date())
	const [duration, setDuration] = useState(null)
	const [endDateTime, setEndDateTime] = useState(new Date())
	const [finalValue, setFinalValue] = useState(formatValue)
	const [disableData, setDisableData] = useState([])

	useEffect(() => {
		if (
			!!fields[field_id] &&
			!!fields[field_id].field_value &&
			!!fields[field_id].field_value.isFromEdit
		) {
			let editValue = fields[field_id].field_value
			let editDuration = options.find(f => f.value === editValue.duration)
			setStartDateTime(new Date(editValue.start_date_time))
			setEndDateTime(new Date(editValue.end_date_time))
			setDuration(editDuration)
			formatValue['duration'] = editValue.duration
			formatValue['start_date_time'] = format(
				new Date(editValue.start_date_time),
				'yyyy-MM-dd HH:mm:ss'
			)
			formatValue['end_date_time'] = format(
				new Date(editValue.end_date_time),
				'yyyy-MM-dd HH:mm:ss'
			)
			setFinalValue(formatValue)
			onValidate(true)
		}
	}, [fields[field_id]])

	useEffect(() => {
		if (field?.field_inline_data?.dependent_field_id) {
			const lab = fieldList.find(
				fields =>
					fields.field_id === field?.field_inline_data?.dependent_field_id
			) //get  selected lab actvity details
			let activityId = ''
			if (Object.keys(formFields).length) {
				activityId =
					formFields[field?.form_id][
						field?.field_inline_data?.dependent_field_id
					]?.['field_value']?.['details']?.['activity_id']
			}

			blockedDatesData(
				{
					activity_type_category_id: 31,
					parent_activity_id: activityId,
					datetime_start: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
					datetime_end: '2050-12-31 11:59:59',
					start_from: 0,
					limit_value: 100,
				},
				{
					onSuccess: data => {
						const disableDateTimes = []
						data?.forEach(item => {
							const inline_data = JSON.parse(item.activity_inline_data)
							if (inline_data?.length) {
								inline_data?.forEach((lineItem, i) => {
									if (lineItem?.field_id === field?.field_id) {
										const fValue = JSON.parse(lineItem?.field_value)
										disableDateTimes.push({
											start_date_time: fValue?.start_date_time,
											end_date_time: fValue?.end_date_time,
										})
									}
								})
							}
						})
						setDisableData(disableDateTimes)
					},
				}
			)
		}
	}, [formFields])
	const onChange = (v, f) => {
		if (f === 'duration') {
			finalValue[f] = v.value
			setDuration(v)
			let set_duration = addMinutes(new Date(startDateTime), v.value)
			let startDate = format(new Date(startDateTime), 'yyyy-MM-dd HH:mm:ss')
			let end_date = format(new Date(set_duration), 'yyyy-MM-dd HH:mm:ss')
			setEndDateTime(new Date(end_date))
			finalValue['end_date_time'] = moment(set_duration).utc()
			finalValue['start_date_time'] = moment(startDate).utc()
		} else if (f === 'start_date_time') {
			setStartDateTime(v)
			let set_duration = addMinutes(v, finalValue['duration'])
			let end_date = format(new Date(set_duration), 'yyyy-MM-dd HH:mm:ss')
			setEndDateTime(new Date(end_date))
			finalValue['end_date_time'] = moment(set_duration).utc()
			finalValue[f] = moment(v).utc()
			// finalValue[f] = format(v, 'yyyy-MM-dd HH:mm:ss')
		} else {
			finalValue[f] = moment(v).utc()
			// finalValue[f] = format(v, 'yyyy-MM-dd HH:mm:ss')
			setEndDateTime(v)
		}
		setFinalValue(finalValue)
		if (Object.keys(finalValue).every(f => finalValue[f] !== '')) {
			onValidate(true)
			dispatch({
				type: smartFormConst.SET_FIELD_VALUE,
				fieldId: field.field_id,
				formId: field.form_id,
				value: loadFinalValue(field, finalValue),
			})
		}
	}

	return (
		<React.Fragment>
			<Text py='2'>Start Datetime</Text>
			<DatePicker
				dateFormat='dd/MM/yyyy hh:mm a'
				customInput={<CustomDateInput field={field} isDisabled={isDisabled} />}
				id={`${field.field_name.split(' ').join('-')}-start-date`}
				showTimeSelect
				disabled={isDisabled}
				showPopperArrow={true}
				selectedDate={startDateTime}
				onChange={e => onChange(e, 'start_date_time')}
				timeIntervals={15}
				minDate={new Date()}
				disableData={disableData}
			/>
			<Text py='2'>Duration</Text>
			<Select
				isDisabled={isDisabled}
				id={field.field_name.split(' ').join('-') + 'duration'}
				menuPlacement='auto'
				options={options}
				onChange={e => onChange(e, 'duration')}
				placeholder={`Select Duration`}
				borderRadius='md'
				value={duration}
				size='sm'
			/>
			<Text py='2'>End Datetime</Text>
			<DatePicker
				dateFormat='dd/MM/yyyy hh:mm a'
				customInput={<CustomDateInput field={field} isDisabled={isDisabled} />}
				id={`${field.field_name.split(' ').join('-')}-end-date`}
				showTimeSelect
				disabled={true}
				showPopperArrow={true}
				selectedDate={endDateTime}
				onChange={e => onChange(e, 'end_date_time')}
				timeIntervals={15}
				minDate={new Date()}
			/>
		</React.Fragment>
	)
}

export default EventSchedule
