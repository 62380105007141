/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Input } from '@chakra-ui/react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { loadFinalValue } from '../../../../../utils/form.util'
/**
 * dataTypeID 75
 * dataTypeName  JSON
 *
 */
const JSONField = ({ field, isRequired, onValidate, isDisabled = true }) => {
  const {
    state: { fields: formFields },
    dispatch,
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}
  const [isEdit, setIsEdit] = useState(false)
  const [fieldValue, setFieldValue] = useState([])

  const onChange = value => {
    if (!!isRequired) {
      if (!!value) {
        onValidate(true)
      } else {
        onValidate(false)
      }
    } else {
      onValidate(true)
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, value),
    })
  }

  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      onChange(fields[field.field_id].field_value)
    }
  }, [])

  useEffect(() => {
    if (
      !!fields[field.field_id] &&
      !!fields[field.field_id].field_value.isFromEdit
    ) {
      let valueJson = fields[field.field_id].field_value.json
      if (Array.isArray(valueJson)) {
        setFieldValue(valueJson)
        setIsEdit(true)
      }
    }
  }, [fields[field.field_id]])

  const headers = {}
  Array.isArray(fieldValue) &&
    fieldValue.forEach(item => {
      Object.keys(item).forEach(key => {
        if (!headers.key) {
          headers[key] = 1
        }
      })
    })

  return (
    <>
      {isEdit ? (
        <div style={{ overflow: 'scroll' }}>
          <table border="1" width="100%" style={{ backgroundColor: '#fff' }}>
            <thead>
              <tr>
                {Object.keys(headers).map((head, i) => {
                  return <th key={i}>{head}</th>
                })}
              </tr>
            </thead>
            <tbody>
              {Array.isArray(fieldValue) &&
                fieldValue.map((item, vkey) => {
                  return (
                    <tr key={vkey}>
                      {Object.keys(headers).map((head, k) => {
                        let val = ''
                        if (typeof item[head] === 'string') {
                          val = !!item[head] ? item[head] : ''
                        } else if (
                          typeof item[head] === 'object' &&
                          Array.isArray(item[head])
                        ) {
                          val = item[head]
                            .filter(Boolean)
                            .map(m => {
                              return Object.entries(m).map(([k, v]) => {
                                return `<b>${k}</b>: ${v}`
                              })
                            })
                            .join(', ')
                        } else {
                          val = [item[head]]
                            .filter(Boolean)
                            .map(m => {
                              return Object.entries(m).map(([k, v]) => {
                                return `<b>${k}</b>: ${v}`
                              })
                            })
                            .join(', ')
                        }
                        return (
                          <td key={k}>
                            <div dangerouslySetInnerHTML={{ __html: val }} />
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </div>
      ) : (
        <Input
          type="text"
          autoComplete="off"
          isDisabled={isDisabled}
          placeholder={field.field_place_holder || `Enter ${field.field_name}`}
          borderRadius="md"
          size="sm"
          value={
            fields[field.field_id] ? fields[field.field_id].field_value : ''
          }
          onChange={e => onChange(e.target.value)}
        />
      )}
    </>
  )
}

export default JSONField
