/* eslint-disable react-hooks/exhaustive-deps */
import {
  InputGroup,
  InputRightAddon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
} from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useSmartFormContext } from '../../../../../context/smart-form.context'
import { smartFormConst } from '../../../../../utils/action.constant'
import { toSnakeCase } from '../../../../../utils/common.util'
import { loadFinalValue, arithmaticCalcu } from '../../../../../utils/form.util'
/* allowMouseWheel to increament the value via mouse wheel */

const CurrencyField = ({
  field,
  isDisabled = true,
  isRequired,
  onValidate,
  fieldList,
}) => {
  const {
    state: { fields: formFields, toReFreshNumVal },
    dispatch,
  } = useSmartFormContext()
  const fields = !!formFields[field.form_id] ? formFields[field.form_id] : {}

  const { currency_name } =
    !!field.field_inline_data && !!field.field_inline_data.currency_restriction
      ? field.field_inline_data.currency_restriction
      : {}

  const onChange = value => {
    if (!!isRequired) {
      if (!!value) {
        onValidate(true)
      } else {
        onValidate(false)
      }
    } else {
      onValidate(true)
    }
    dispatch({
      type: smartFormConst.SET_FIELD_VALUE,
      fieldId: field.field_id,
      formId: field.form_id,
      value: loadFinalValue(field, value),
    })
    dispatch({
      type: smartFormConst.TO_REFRESH_NUM_VAL,
    })
  }
  const checkJson = value => {
    try {
      let obj = JSON.parse(value)
      return typeof obj === 'object'
    } catch (e) {
      return false
    }
  }

  let value1 = !!fields[field.field_id]
    ? fields[field.field_id].field_value
    : ''
  let value = !!value1.value ? value1.value : value1

  useEffect(() => {
    if (!!fields[field.field_id] && fields[field.field_id].field_value) {
      let result = checkJson(fields[field.field_id].field_value)
      if (result) {
        let json = JSON.parse(fields[field.field_id].field_value)
        onChange(json.value || '')
      } else {
        onChange(
          !!fields[field.field_id].field_value.value
            ? fields[field.field_id].field_value.value
            : fields[field.field_id].field_value || ''
        )
      }
    }
  }, [!!fields[field.field_id]])

   useEffect(() => {
     if (
       !!field?.field_inline_data?.formula?.length &&
       !!formFields[field.form_id]
     ) {
       dispatch({
         type: smartFormConst.SET_FIELD_VALUE,
         fieldId: field.field_id,
         formId: field.form_id,
         value: loadFinalValue(
           field,
           arithmaticCalcu(
             field,
             formFields[field.form_id],
             fieldList
           ).toString()
         ),
       })
       onValidate(true)
     }
   }, [toReFreshNumVal])

  return (
    <InputGroup>
      <NumberInput
        w="full"
        onChange={onChange}
        value={value}
        isDisabled={isDisabled}
      >
        <NumberInputField
          placeholder={field.field_place_holder || `Enter ${field.field_name}`}
        />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
      <InputRightAddon
        id={`input-currency-addon-${toSnakeCase(currency_name)}`}
        children={currency_name}
      />
    </InputGroup>
  )
}

export default CurrencyField
